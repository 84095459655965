import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AccountState {
  name: string | null;
}

const initialState: AccountState = {
  name: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    clearAccount(state) {
      state.name = null;
    },
  },
});

export const { setAccount, clearAccount } = accountSlice.actions;
export default accountSlice.reducer;
