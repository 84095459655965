import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import categoriesReducer from './features/categoriesSlice';
import favoritesReducer from './features/favoritesSlice';
import servicesReducer from './features/servicesSlice';
import filterReducer from './features/filterSlice';
import accountServicesReducer from './features/accountServicesSlice';
import accountReducer from './features/accountSlice';
import clientsReducer from './features/accountClientsSlice'

export const stStore = configureStore({
  reducer: {
    categories: categoriesReducer,
    favorites: favoritesReducer,
    services: servicesReducer,
    accountServices: accountServicesReducer,
    clients: clientsReducer,
    filter: filterReducer,
    account: accountReducer,
  },
});

export type STDispatch = typeof stStore.dispatch;

// export const useSTDispatch: () => STDispatch = useDispatch;
export const useSTDispatch: () => STDispatch = useDispatch;

export type STState = ReturnType<typeof stStore.getState>;
