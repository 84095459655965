import { PublicAccountService, PublicAccountServices } from '../models/PublicAccountServices';
import { PublicServiceChart, PublicServiceHistoryResponse } from '../models';
import { api } from './configs/axiosConfigs';

export const PublicAccountServicesAPI = {
    getPaginated: async function ({
    page = 0,
    pageSize = 6,
    name,
    category,
    account,
  }: {
    page?: number;
    pageSize?: number;
    name?: string;
    category?: string;
    account: string;
  }): Promise<PublicAccountServices> {
    if (!account) {
      throw new Error("Account not found in subdomain");
    }
    const response = await api.request<PublicAccountServices>({
      url: `/public/${account}/services`,
      method: 'GET',
      params: {
        page,
        pageSize,
        name,
        category,
      },
    });

    return response.data;
    },

    getAll: async function ({ category, name, account }: { category?: string; name?: string, account: string }): Promise<any[]> {
        const allData: any[] = [];
        let currentPage = 0;
    
        const loadPage = async (): Promise<any[]> => {
          return this.getPaginated({ page: currentPage, category, name, account }).then(data => {
            allData.push(...data.data);
            currentPage++;
            if (data.totalPageCount > currentPage) {
              return loadPage();
            }
    
            return Promise.resolve(allData);
          });
        };
    
        return loadPage();
    },

    getById: async function (serviceId: string): Promise<PublicAccountService> {    
        const response = await api.request<any>({
        url: `/public/services/${serviceId}`,
        method: 'GET',
        params: {
            page: 0,
            pageSize: 1,
            serviceId,
        },
        });
    
        return response.data;
    },

    getChart: async function (serviceId: string): Promise<PublicServiceChart> {
        const response = await api.request<PublicServiceChart>({
        url: `/public/${serviceId}/chart`,
        method: 'GET',
        });
        return response.data;
    },

    getHistory: async function (serviceId: string): Promise<PublicServiceHistoryResponse> {
        const response = await api.request<PublicServiceHistoryResponse>({
        url: `/public/${serviceId}/history`,
        method: 'GET',
        params: {
            page: 0,
            pageSize: 1000,
        },
        });
        return response.data;
    },
};
