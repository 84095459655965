import { useState } from 'react';
import { STSelect } from './STSelect';
import { STInput } from './STInput';
import './SearchForm.css';
import { PublicCategory } from '../../models';
import { Button } from 'antd';
import { toStartUpperCase } from '../../utils';
import { useSelector } from 'react-redux';
import { STState } from '../../stStore';
import { formatHexCode } from '../../utils/hexcode-helper';

interface SearchFormProps {
  categories: PublicCategory[];
  loading: boolean;
  onSubmit: (e: string, category: string | undefined) => void;
}

export function SearchForm({ onSubmit, categories, loading }: SearchFormProps) {
  const [filterText, setFilterText] = useState<string>('');
  const [category, setCategory] = useState<number | undefined>(undefined);
  const clientsDashboard = useSelector((state: STState) => state.clients.data);
  const buttonColor = clientsDashboard?.publicDashboardCustomization.buttonColor;

  return (
    <div className="flex-row SearchForm__wrapper compact half-px-border">
      <div className="flex-row input-wrapper">
        <div className={`${clientsDashboard ? 'w100' : 'w50'}`}>
          <STInput placeholder="Search brand" onChange={text => setFilterText(text)} />
        </div>
        {!clientsDashboard && (
        <>
        <div className="separator-30"></div>
        <div className="w50">
          <STSelect
            onChange={category => setCategory(category)}
            items={categories.map(c => {
              return { key: c.key, name: toStartUpperCase(c.name) };
            })}
          />
        </div>
        </>
        )}
      </div>
      <Button
        onClick={() => onSubmit(filterText, categories.find(c => c.key === category)?.name)}
        disabled={loading}
        className="submit-button"
        type="primary"
        style={{backgroundColor: buttonColor 
          && formatHexCode(buttonColor) 
        }}
      >
        Search
      </Button>
    </div>
  );
}
