import dayjs from 'dayjs';
import { StatusLogo } from './StatusLogo';
import './StatusLogo.css';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { PublicService } from '../../models';
import './StatusCardHeader.css';

interface StatusCardProps {
  service: PublicService;
  isFavorite: boolean;
  onClickFavorite: (id: string, category: string) => void;
  onClickTitle?: () => void;
}

export function StatusCardHeader({ service, isFavorite, onClickFavorite, onClickTitle }: StatusCardProps) {
  return (
    <>
      <div className="flex-row StatusCardHeader">
        <StatusLogo name={service?.name} logoUrl={service?.logoUrl} />
        <div className="title">
          <h3 className={onClickTitle ? 'clickable' : ''} onClick={() => onClickTitle && onClickTitle()}>
            {service?.name}
          </h3>
          {service?.lastStatusCheck && (
            <div className="label-wrapper">
              <div className="label">Last Checked</div>
              <div className="date">
                {dayjs.unix(service?.lastStatusCheck).format('MMM. DD, YYYY')} &#x2022;{' '}
                {dayjs.unix(service?.lastStatusCheck).format('hh:mm A')}
              </div>
            </div>
          )}
        </div>
        <div className="favorite-wrapper">
          {isFavorite ? (
            <StarFilled
              className="favorite-icon -saved"
              onClick={() => onClickFavorite(service?.serviceId, service?.category)}
            />
          ) : (
            <StarOutlined
              className="favorite-icon icon-thin"
              onClick={() => onClickFavorite(service?.serviceId, service?.category)}
            />
          )}
        </div>
      </div>
      <div className="separator-horizontal" />
    </>
  );
}
