import logo from '../../assets/logo.svg';
import { Button, Dropdown } from 'antd';
import './STHeader.css';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { STState } from '../../stStore';
import { formatHexCode } from '../../utils/hexcode-helper';

export function STHeader() {
  const account = useSelector((state: STState) => state.account.name);
  const clientsDashboard = useSelector((state: STState) => state.clients.data);

  const getTargetUrl = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return '/coming-soon';
    } else {
      return process.env.REACT_APP_WEB_APP_URL;
    }
  };
  
const items = [
  {
    key: '1',
    label: (
      <Link to="/about" target="_blank">
        Go to Official Website
      </Link>
    ),
  },
  ...( !account
    ? [{
        key: '2',
        label: (
          <Link to={getTargetUrl() || "/"} target="_blank">
            Sign In
          </Link>
        ),
      }]
    : []
  ),
];

const buttonColor = clientsDashboard?.publicDashboardCustomization.buttonColor;

  return (
    <header className="flex-row STHeader__wrapper plr-page pt-lg">
      <div className="logo">
        <img src={clientsDashboard?.mainLogoUrl ? clientsDashboard.mainLogoUrl : logo} alt="logo" />
      </div>
      <Dropdown className="hoverable desktop-hidden" menu={{ items }} placement="bottomLeft">
        <MenuOutlined style={{ fontSize: '28px', color: '#1e1e1e' }} />
      </Dropdown>

      {!account ? (
        <Button onClick={() => window.open('/about', '_blank')} className="mobile-hidden" size="large">
          Go to Official Website
        </Button>
      ):(
        <Button 
        onClick={() => window.open(clientsDashboard?.website.startsWith('http') ? clientsDashboard.website : `http://${clientsDashboard?.website}`, '_blank')} // Ensure it has a valid protocol
        className="mobile-hidden"
        type='primary'
          size="large" 
          style={{
            backgroundColor: account && buttonColor
              && formatHexCode(buttonColor),
          }}
        >
          Go to Official Website
        </Button>
      )}

      {!account && (
        <Button
          onClick={() => window.open(getTargetUrl(), '_blank')}
          className="mobile-hidden"
          type="primary"
          style={{backgroundColor: buttonColor 
            && formatHexCode(buttonColor) }}
          size="large"
        >
          Sign In
        </Button>
      )}
    </header>
  );
}
