import { PublicAccountClients } from '../models/PublicAccountClients';
import { api } from './configs/axiosConfigs';

export const PublicAccountClientsAPI = {
  getClientsCategory: async function (account: string): Promise<PublicAccountClients> {
    if (!account) {
      throw new Error("Account not found in subdomain");
    }
    const response = await api.request<any>({
      url: `/public/${account}/clients`,
      method: 'GET',
    });

    return response.data;
  },
};
