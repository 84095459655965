import { useState } from 'react';
import './StatusLogo.css';

interface StatusLogoProps {
  logoUrl?: string;
  name: string;
}

export function StatusLogo({ logoUrl, name }: StatusLogoProps) {
  const [isLogoMissing, setIsLogoMissing] = useState<boolean>(false);
  return (
    <>
      {logoUrl && !isLogoMissing ? (
        <img alt={name} src={logoUrl} onError={() => setIsLogoMissing(true)} className='StatusLogo' />
      ) : (
        <div className="StatusLogo__placeholder">{name?.slice(0, 1)}</div>
      )}
    </>
  );
}
