import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ReactTagManager } from 'react-gtm-ts';
import './index.css';
import './styles/icon.css';
import './styles/layout.css';
import './styles/ant-overrides.css';
import PublicSearch from './pages/PublicSearch';
import { STLayout } from './pages/STLayout';
import { ServiceDetail } from './pages/ServiceDetail';
import { stStore } from './stStore';
import { Provider, useSelector } from 'react-redux';
import { Website } from './pages/Website';
import { ComingSoon } from './pages/ComingSoon';
import { STState } from './stStore';

const router = createBrowserRouter([
  {
    path: '',
    element: <STLayout />,
    children: [
      {
        path: '',
        element: <PublicSearch />,
      },
      {
        path: ':id',
        element: <ServiceDetail />,
      },
    ],
  },
  {
    path: 'about',
    element: <Website />,
  },
  {
    path: 'coming-soon',
    element: <ComingSoon />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

const App = () => {
  const clientsDashboard = useSelector((state: STState) => state.clients.data);

  const theme = {
    token: {
      fontFamily: 'Outfit',
      colorPrimary: clientsDashboard?.publicDashboardCustomization?.backgroundColor || '#7752fe',
    },
    components: {
      Modal: { borderRadiusLG: 30 },
      Select: { fontSizeIcon: 16, fontSize: 17 },
      Input: { fontSize: 17 },
      Button: {
        fontSize: 20,
        paddingContentHorizontal: 20,
        fontWeight: 500,
        borderRadius: 5,
        controlHeight: 42,
        colorBgContainerDisabled: '#ffffff',
        fontSizeLG: 17,
        paddingInlineLG: 20,
        controlHeightLG: 45,
        defaultBg: 'transparent',
        defaultColor: '#7752fe',
        defaultBorderColor: '#7752fe',
      },
      Dropdown: { fontSize: 17 },
    },
  };

  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

ReactTagManager.init({
  code: 'GTM-5J99FPWM',
  debug: false,
  performance: false,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={stStore}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
